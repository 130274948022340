import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ButtonComponent } from './button.component';
import { ViewPhotoIconModule } from '../icons/view-photo-icon/view-photo-icon.module';
import { ChevronIconModule } from '../icons/chevron/chevron-icon.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MapIconComponent } from '../icons/map/map-icon.component';
import { ArrowDownIconComponent } from '../icons/arrow-down/arrow-down-icon.component';

@NgModule({
	declarations: [ButtonComponent],
	imports: [SharedModule, MatBadgeModule, ViewPhotoIconModule, ChevronIconModule, MapIconComponent, ArrowDownIconComponent],
	exports: [ButtonComponent],
})
export class ButtonModule {}
